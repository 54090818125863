export default {
    START_STUDIO: 10,
    LOAD_DOCUMENT: 15,
    SAVE_DOCUMENT: 17,
    ERROR_BOUNDARY: 18,
    PDF_PROOF_PROCESSING: 20,
    DUPLICATE_PROJECT: 22,
    RENAME_PROJECT: 23,
    CONTINUE_ERROR: 50,
    SECTIONS: 100,
    VORTEX: 101
};
