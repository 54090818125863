import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { BookendsMetaTag, BOOKEND_TYPES, Header } from "@vp/esi-bookends";
import { Helmet } from "react-helmet";
import {
    DesignExperienceErrorUi,
    ErrorIconTypes,
    ErrorIconColor,
    ErrorDetail
} from "@design-stack-vista/design-experience-error-ui";
import { BoundedContent } from "@vp/swan";
import { usePageContext } from "../../providers/PageContextProvider";
import { useCalcifer } from "../../providers/CalciferProvider";
import { useErrorContext } from "../../providers/ErrorProvider";
import { useTrackingContext, DEFAULT_PAGENAMEBASE } from "../../providers/TrackingProvider";
import messages from "./errorMessages";

import "./errorPage.scss";

interface ErrorPageProps {
    errorCodeOverride?: string;
    errorMessageOverride?: string;
}

export function ErrorPage({ errorCodeOverride, errorMessageOverride }: ErrorPageProps) {
    const { locale, careUrl, homeUrl } = usePageContext();
    const calciferConfig = useCalcifer();
    const { errorData } = useErrorContext();
    const { t } = useTranslationSSR();
    const { getTrackingInfoBase } = useTrackingContext();

    return (
        <>
            <BookendsMetaTag
                renderWith={Helmet}
                locale={locale}
                tenant="vistaprint"
                headerType={BOOKEND_TYPES.FULL}
                footerType={BOOKEND_TYPES.FULL}
                hideSearch={false}
                hideVat={false}
            />
            <Header
                locale={locale}
                tenant="vistaprint"
                type={BOOKEND_TYPES.FULL}
                hideSearch={false}
                hideVat={false}
                trackingConfiguration={getTrackingInfoBase(DEFAULT_PAGENAMEBASE, true)}
            />
            <BoundedContent>
                <div className="error-page-container">
                    <DesignExperienceErrorUi
                        Icon={<ErrorIconTypes.Default className={ErrorIconColor.DesignTechTribe} />}
                        errorHeader={t(messages.defaultErrorHeader.id)}
                        errorSubHeader={t(messages.defaultErrorSubHeader.id)}
                        primaryButtonMessage={t(messages.getHelpButtonText.id)}
                        primaryOnClick={() => {
                            window.location.href = careUrl;
                        }}
                        secondaryButtonMessage={t(messages.returnToHomeButtonText.id)}
                        secondaryOnClick={() => {
                            window.location.href = homeUrl;
                        }}
                        errorDetailChildren={
                            <>
                                <ErrorDetail
                                    name="Code"
                                    value={errorCodeOverride || errorData?.errorCode || "Unknown"}
                                />
                                <ErrorDetail
                                    name="Message"
                                    value={errorMessageOverride || errorData?.errorMessage || "Unknown"}
                                />
                                <ErrorDetail name="Date" value={new Date().toUTCString()} />
                                <ErrorDetail name="ProductKey" value={calciferConfig?.productKey || "Unknown"} />
                            </>
                        }
                    />
                </div>
            </BoundedContent>
        </>
    );
}

ErrorPage.displayName = "ErrorPage";
