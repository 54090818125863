import React, { createContext, ReactNode, useCallback, useContext, useMemo } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { CimDoc } from "@design-stack-ct/cdif-types";
import { getGeneralTrackingData, getStudioTrackingMetadata } from "../tracking/generalTrackingData";
import { windowExists, getQueryParams, tryParse } from "../utilities/utilities";
import { useCalcifer } from "./CalciferProvider";
import { usePageContext } from "./PageContextProvider";
import { exceptionEventOnExperiment, experimentConfigObj } from "../utilities/ABTests";
import { useInitialDocument } from "../hooks/useInitialDocument";

export const DEFAULT_PAGENAMEBASE = "Studio";

declare global {
    interface Window {
        tracking: any;
    }
}
interface TrackEventProps {
    label?: string;
    pageNameBase?: string;
    event?: string;
    eventDetail?: string;
    timeSinceLoad?: number;
    extraData?: () => void;
    firePage?: boolean;
    category?: string;
}

interface TrackingProps {
    trackEvent: ({
        label,
        pageNameBase,
        event,
        eventDetail,
        timeSinceLoad,
        extraData,
        firePage,
        category
    }: TrackEventProps) => void;
    getTrackingInfoBase: (pageNameBase?: string, isErrorPage?: boolean) => TrackingBase;
}

interface TrackingBase {
    pageName: string;
    pageSection: string;
    pageStage: string;
    languageLocale: string;
}

interface Props {
    children: ReactNode;
}

const TrackingContext = createContext<TrackingProps>({
    trackEvent: ({
        label,
        pageNameBase,
        event,
        eventDetail,
        timeSinceLoad,
        extraData,
        firePage,
        category
    }: TrackEventProps) => {},
    getTrackingInfoBase: (pageNameBase?: string, isErrorPage = false) => ({
        pageName: "",
        pageSection: "",
        pageStage: "",
        languageLocale: ""
    })
});

export const useTrackingContext = () => {
    return useContext(TrackingContext);
};

const firePageEvent = (trackingBase: TrackingBase) => {
    if (!windowExists()) {
        return;
    }
    window.tracking.page(trackingBase);
};

function generateDocumentMetadata(document: CimDoc | undefined) {
    const firstPanelId = document?.document.panels[0].id;
    const documentSource =
        document?.metadata?.documentSources?.panels?.find((p: { id: string | undefined }) => p.id === firstPanelId)
            ?.source ?? document?.metadata?.documentSources?.panels?.[0]?.source;
    if (documentSource) {
        return { documentSource };
    }
    return {};
}

function getQueryStringTrackingData() {
    const queryParams = getQueryParams();
    const quickStudioAnalytics = tryParse(queryParams.quickStudioAnalytics);
    const galleryMetadata = tryParse(queryParams.metadata);

    return {
        quickStudioAnalytics,
        galleryMetadata
    };
}

export const TrackingProvider = (props: Props) => {
    const calciferConfig = useCalcifer();
    const { locale } = usePageContext();
    const { auth } = useIdentityContext();
    const accessToken = auth?.getToken();
    const { cimDoc } = useInitialDocument({ calciferConfig, accessToken });
    const { workId } = getQueryParams();
    const { children } = props;

    const getTrackingInfoBase = useCallback(
        (pageNameBase?: string, isErrorPage = false) => {
            return {
                pageName: isErrorPage
                    ? `${pageNameBase || DEFAULT_PAGENAMEBASE}:ErrorPage` // pageName for the Error Page does not have an associated mpvId
                    : `${calciferConfig?.mpvId}:${pageNameBase || DEFAULT_PAGENAMEBASE}`, // pageName is of the format mpvID:pageSection
                pageSection: DEFAULT_PAGENAMEBASE, // Section of the page, which will correspond to the functional side of it
                pageStage: "Design", // Stage of the page, which correspond to what we want the customer do in the website
                languageLocale: locale // 2 chars for language (without the notion of a dialect/version of the language)
                // and 2 chars for the locale
            };
        },
        [calciferConfig?.mpvId, locale]
    );

    const internalTrackEvent = useCallback(
        ({
            label,
            pageNameBase,
            event,
            eventDetail,
            timeSinceLoad,
            extraData,
            firePage,
            category
        }: TrackEventProps) => {
            const trackingBase = getTrackingInfoBase(pageNameBase);
            if (firePage) {
                firePageEvent(trackingBase);
            }
            let trackingEvent = event || "Product Viewed";

            const productKey = calciferConfig?.productKey;

            const experimentConfig = productKey && experimentConfigObj[productKey];
            const isExperimentalEvent =
                experimentConfig && event && !exceptionEventOnExperiment.some((e) => e === event);

            if (isExperimentalEvent) {
                trackingEvent = "Experiment Clicked";
            }

            const trackingData = {
                category, // Category influences whether an event is routed to GA.
                label, // the event label name
                eventDetail, // detail of events
                pageSection: trackingBase.pageSection, // the section of the page
                pageStage: trackingBase.pageStage, // the stage of the page
                pageName: trackingBase.pageName, // detailed name of the page where the event has been sent
                product_id: calciferConfig?.mpvId, // MPVID
                workId, // Work Id, if available
                // documentUrl: currentState?.document?.documentUrl, // Doc URL if available
                name: calciferConfig?.productName, // Detailed Product Name / MPV
                core_product_id: productKey, // Set to custom dimension
                core_product_version: calciferConfig?.productVersion
                    ? Number(calciferConfig.productVersion)
                    : undefined,
                ...(extraData ? extraData() : {}),
                ...getGeneralTrackingData(),
                timeSinceLoad: timeSinceLoad || performance.now(), // time since load
                ...getStudioTrackingMetadata(),
                ...generateDocumentMetadata(cimDoc),
                ...getQueryStringTrackingData(),
                studioVersion: "packaging",
                ...(isExperimentalEvent ? { ...experimentConfig } : {})
            };

            window.tracking.track(trackingEvent, trackingData);

            if (trackingEvent === "Product Viewed") {
                window.tracking.track("Studio Tracking", {
                    ...trackingData,
                    eventDetail: label === "Studio page view" ? "Studio Page" : "Studio Review Page",
                    label: label === "Studio page view" ? "Studio page View" : "Studio review page view"
                });
            }
        },
        [
            calciferConfig?.mpvId,
            calciferConfig?.productKey,
            calciferConfig?.productName,
            calciferConfig?.productVersion,
            getTrackingInfoBase,
            workId,
            cimDoc
        ]
    );

    const contextObject = useMemo(
        () => ({
            trackEvent: (trackingProps: TrackEventProps) => {
                if (window.tracking && window.tracking.page && window.tracking.track) {
                    internalTrackEvent(trackingProps);
                } else {
                    window.addEventListener("trackingReady", () => {
                        internalTrackEvent(trackingProps);
                    });
                }
            },
            getTrackingInfoBase
        }),
        [internalTrackEvent, getTrackingInfoBase]
    );
    return <TrackingContext.Provider value={contextObject}>{children}</TrackingContext.Provider>;
};

TrackingProvider.displayName = "TrackingProvider";
