import { initI18n, i18n } from "@vp/i18n-helper";
import traverse from "traverse";
import { getQueryParams, isDevelopmentMode, windowExists } from "./utilities";
import defaultMessagesJSON from "../../i18n/en-US.json";

function getTranslationsLocale(locale: string) {
    // if we are running locally or with studio_bundle_source, use local copy unless explicitly specified in a QP
    return getQueryParams().translationsLocale || getQueryParams().locale || (isDevelopmentMode() ? "local" : locale);
}

export default async function initializeI18n(locale: string, studioTranslations: Record<string, string>) {
    const translationsLocale = getTranslationsLocale(locale).toLowerCase();

    const localeMessages =
        translationsLocale === "local"
            ? { ...defaultMessagesJSON }
            : // start with the default messages in order to provide fallbacks for en-us
              {
                  ...defaultMessagesJSON,
                  ...studioTranslations
              };

    const setup = {
        defaultMessages: defaultMessagesJSON,
        projectName: "packagingStudio",
        translationsLocale,
        baseURL: "https://translations.design.vpsvc.com",
        setCultureOnBody: windowExists(),
        overrides: {
            debug: false
        },
        localeMessages
    };
    return initI18n(setup);
}

export function provideTranslations(original: Record<string, any>, prefix: string) {
    // eslint-disable-next-line func-names, array-callback-return
    const replaced = traverse(original).map(function (node) {
        if (this.isLeaf && typeof node === "string") {
            // @ts-ignore
            this.update(i18n.t(`${prefix}.${this.path.join(".")}`));
        }
    });
    return replaced;
}
