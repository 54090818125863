import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useStudio6ABTestProvider } from "./Studio6ABTestProvider";
import { isProductionSite, windowExists } from "../utilities";
import { usePatternABTest } from "./PatternABTestProvider";

function createStudio6Url() {
    const { origin, search, pathname } = window.location;
    if (isProductionSite()) {
        return `${origin}/studio6/${search}`;
    }

    return `https://staging.five.studio.design.vpsvc.com/studio/six${pathname}${search}`;
}

export default function Studio6ABTestWrapper({ children }: { children: ReactNode }) {
    const {
        isStudio6Enabled,
        isInitialized: isStudio6ABInitialized,
        isTestActive: isStudio6ABTestActive
    } = useStudio6ABTestProvider();
    const {
        isStudio6PatternEnabled,
        isInitialized: isPatternABTestInitialized,
        isTestActive: isPatternABTestActive
    } = usePatternABTest();
    const redirectTimeout = useRef<number>();
    const [continueToPackagingStudio, setContinueToPackagingStudio] = useState(false);

    useEffect(() => {
        if (!redirectTimeout.current) {
            // In case ab initialization fails or is super-delayed we'll just send the user to studio5 so they aren't stuck
            redirectTimeout.current = window.setTimeout(() => {
                setContinueToPackagingStudio(true);
            }, 5000);
        }
    }, []);

    useEffect(() => {
        if (isStudio6ABInitialized || isPatternABTestInitialized) {
            if (isStudio6ABTestActive || isPatternABTestActive) {
                // check isStudio6Enabled for the user
                if (isStudio6Enabled || isStudio6PatternEnabled) {
                    // redirect to studio6 if in variation
                    const studio6Url = createStudio6Url();
                    window.location.replace(studio6Url);
                } else {
                    // continue to packaging studio if in control
                    setContinueToPackagingStudio(true);
                    clearTimeout(redirectTimeout.current);
                    redirectTimeout.current = undefined;
                }
            } else {
                // the test is not active, continue to packaging studio
                setContinueToPackagingStudio(true);
                clearTimeout(redirectTimeout.current);
                redirectTimeout.current = undefined;
            }
        }
    }, [
        isStudio6ABInitialized,
        isPatternABTestActive,
        isPatternABTestInitialized,
        isStudio6Enabled,
        isStudio6PatternEnabled,
        isStudio6ABTestActive
    ]);

    if (!windowExists()) return null;

    return continueToPackagingStudio ? (
        <>{children}</>
    ) : (
        <link rel="prefetch" href={createStudio6Url()} as="document" />
    );
}

Studio6ABTestWrapper.displayName = "Studio6ABTestWrapper";
