import { useEffect, useState } from "react";
import { CimDoc } from "@design-stack-ct/cdif-types";
import { getDocument } from "@design-stack-ct/documents-sdk";
import { AuthConfiguration, convertToAuthHeaders } from "@design-stack-ct/utility-core/dist/auth";
import { upgradeCimDoc } from "@design-stack-ct/cimdoc-state-manager";
import { getQueryParams } from "../utilities/utilities";
import { CalciferResponse } from "../clients/calciferClient";
import { useInitialProductData } from "./useInitialProductData";
import { useErrorContext, ErrorType } from "../providers/ErrorProvider";
import config from "../utilities/config";
import errorCodes from "../utilities/errorCodes";

export interface UseInitialDocumentProps {
    calciferConfig?: CalciferResponse;
    documentReferenceUrl?: string | null;
    accessToken: string;
}

export function useInitialDocument({ calciferConfig, accessToken }: UseInitialDocumentProps) {
    const { showError } = useErrorContext();
    const [loading, setLoading] = useState(true);
    const [cimDoc, setCimDoc] = useState<CimDoc | undefined>();
    const [savedCimDoc, setSavedCimDoc] = useState<CimDoc | undefined>();
    const { documentUrl } = useInitialProductData();

    useEffect(() => {
        if (!accessToken || cimDoc || savedCimDoc) {
            return;
        }
        const authConfig: AuthConfiguration = { authType: "bearer", value: accessToken };
        const authHeaders = convertToAuthHeaders(authConfig);
        (async () => {
            setLoading(true);
            try {
                let cimDocPromise: Promise<CimDoc | null>;
                if (documentUrl) {
                    cimDocPromise = getDocument(documentUrl, { headers: authHeaders }).then(
                        (document) => document.cimDoc
                    );
                } else {
                    cimDocPromise = Promise.resolve(null);
                }

                const possibleCimdoc = await cimDocPromise;

                if (possibleCimdoc) {
                    setSavedCimDoc(possibleCimdoc);
                }
            } catch (error) {
                setSavedCimDoc(undefined);
                showError(ErrorType.page, {
                    errorCode: `${config.entityCode}-${errorCodes.LOAD_DOCUMENT}`,
                    errorMessage: error.message
                });
            }
        })();
    }, [documentUrl, accessToken, cimDoc, savedCimDoc, showError]);

    useEffect(() => {
        if (!cimDoc) {
            const { workId } = getQueryParams();
            if (!(documentUrl || workId) && calciferConfig && calciferConfig?.designDocument) {
                setCimDoc(upgradeCimDoc(calciferConfig?.designDocument));
                setLoading(false);
            } else if (savedCimDoc) {
                setCimDoc(upgradeCimDoc(savedCimDoc));
                setLoading(false);
            }
        }
    }, [documentUrl, calciferConfig, savedCimDoc, cimDoc]);

    return {
        loading,
        cimDoc
    };
}
