import React, { useContext, createContext, useState, useMemo, ReactNode } from "react";
import { useAsyncEffect } from "@design-stack-ct/utility-react";
import { useCalcifer } from "../../providers/CalciferProvider";
import { useAbTestContext } from "./ABTestContext";

type ContextData = {
    // Is the user in the studio6 variant
    isStudio6Enabled: boolean;
    // Is the test currently active
    isTestActive: boolean;
    // Have we checked whether the test is active and supported for the current product
    isInitialized: boolean;
};

const context = createContext<ContextData | undefined>(undefined);

export function useStudio6ABTestProvider() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within a Studio6ABTestProvider");
    }
    return result;
}

/**
 * control is packaging-studio
 * variation is studio6
 */
enum Variations {
    Control = "control",
    Enabled = "variation"
}

export const Studio6ExperimentData: ExperimentData = {
    experimentKey: "packaging_dex_vs_studio_6",
    experimentName: "Packaging DEX Studio 6 Test",
    variations: Variations
};

const studio6TestPRDs = [
    // NA
    "PRD-F0X11PZ3Q", // Mailer Boxes
    "PRD-Y1LEPL3MX", // Shipping Boxes
    "PRD-IRA9J9ZYN", // Product Boxes
    "PRD-RVNI6YXTA", // Pillow Boxes
    "PRD-CNJNMBZX9", // Stand up Pouches
    "PRD-KDCYEUDEL", // Flat Pouches
    "PRD-1O9IXZOA9", // Top Tuck Box
    "PRD-WQ476CAP7", // Square Mailer
    "PRD-QYSK5N7CE", // Top Tuck Mailer Box
    "PRD-QFZMXW6MA", // Flat Shipping Box
    "PRD-OUVYY2FRC", // Lock Bottom Boxes
    "PRD-MDOVMP6NL", // Plastic Cups
    "PRD-UAPTBKAXO", // Custom Gable Box
    "PRD-ASIEDCOUH", // Auto Lock Bottom Boxes
    // EU
    "PRD-1NYANXIDO", // Shipping Boxes
    "PRD-HQQHRNYV", // Product Boxes
    "PRD-YPOBNRUF", // Pillow Boxes
    "PRD-ERH5MYDSB", // Pull Out Boxes
    "PRD-N9KVUFGCQ", // Postal Boxes
    "PRD-ATRKCP22", // Stand Up Pouches
    "PRD-70BBAGZUB", // Flat Pouches
    "PRD-RMLMHJKSE", // Recyclable Pouches
    "PRD-YPIVR9IK7", // Paper Cups
    "PRD-CTB5UVMPP", // Flip Lid Boxes
    "PRD-WOPGFEB2F", // Ice Cream Cups
    "PRD-2YDCCDI3S", // Boxes with Handles
    "PRD-S7USUOJFN", // Book Boxes
    "PRD-UN2AAJKLC", // Lock Bottom Boxes
    "PRD-QO9EMBUDQ", // Cardboard Takeaway Boxes
    "PRD-ULVYR5LYL", // Cardboard Food Trays
    "PRD-U5YTQTRQV", // Full Print Paper Bags
    // AU
    "PRD-YPKEDTODB", // Mailer Box New
    "PRD-I7GQ8DI9C", // Wine Boxes
    // IN
    "PRD-NXPTD4KVV", // Product Boxes
    "PRD-N4LUWYYOP", // Stand Up Custom Pouches
    "PRD-WLO434QXK" // Flat Pouches
];

const isPRDInStudio6Test = (productKey: string): boolean => {
    return studio6TestPRDs.includes(productKey);
};

type Props = {
    children: ReactNode | ReactNode[];
};

export const Studio6ABTestProvider = ({ children }: Props) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const { experimentKey } = Studio6ExperimentData;
    const [isInitialized, setIsInitialized] = useState(false);
    const [isStudio6Enabled, setIsStudio6Enabled] = useState(false);
    const [isTestActive, setIsTestActive] = useState(false);
    const calciferConfig = useCalcifer();
    const productKey = calciferConfig?.productKey;

    useAsyncEffect(
        (helpers) => {
            if (!experimentKey || !ABTest || !productKey || isInitialized) {
                return;
            }
            (async () => {
                const { isExperimentUsingVariation, isExperimentActive, getForcedVariation, trackImpression } = ABTest;
                const experimentActive =
                    Boolean(getForcedVariation(experimentKey)) || (await isExperimentActive(experimentKey));
                helpers.runIfMounted(() => setIsTestActive(experimentActive));

                if (isPRDInStudio6Test(productKey)) {
                    const trackImpressionIfInVariant = async (variation: Variations) => {
                        const res = await isExperimentUsingVariation(experimentKey, variation);
                        if (res) {
                            // only track impression for control
                            // impressions for variation will be tracked in studio6
                            if (variation === Variations.Control) {
                                trackImpression(experimentKey, variation);
                            }
                            helpers.runIfMounted(() => setIsStudio6Enabled(variation !== Variations.Control));
                        }
                    };
                    await Promise.all([
                        trackImpressionIfInVariant(Variations.Enabled),
                        trackImpressionIfInVariant(Variations.Control)
                    ]);
                }

                helpers.runIfMounted(() => setIsInitialized(true));
            })();
        },
        [experimentKey, ABTest, productKey, isInitialized]
    );

    const contextObject = useMemo(
        () => ({
            isStudio6Enabled,
            isInitialized,
            isTestActive
        }),
        [isStudio6Enabled, isInitialized, isTestActive]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

Studio6ABTestProvider.displayName = "Studio6ABTestProvider";
