import React, { createContext, useEffect, useState, useMemo, useContext, useCallback, ReactNode } from "react";
import * as reader from "@vp/ab-reader";
import impression from "@vp/ab-impression";
import { getQueryParams, isDevelopmentMode, windowExists } from "../utilities";
import newRelicWrapper from "../../clients/newRelicWrapper";
import { usePageContext } from "../../providers/PageContextProvider";

interface Data {
    /**
     * Returns a boolean saying whether or not the variation is set
     * Will return true or false
     */
    isExperimentUsingVariation: (experimentKey: string, variationKey: string) => Promise<boolean>;
    /**
     * Given an experiment and variation key, will return whether or not the variation is a forced one
     */
    isForcedVariation: (experimentKey: string, variationKey: string) => boolean;

    getForcedVariation: (experimentKey: string) => string;

    isExperimentActive: (experimentKey: string) => Promise<boolean>;

    trackImpression: (experimentKey: string, variationKey: string) => Promise<void>;

    getVariation: (experimentKey: string) => string;
}

export const AbTestContext = createContext<Data | undefined>(undefined);

export const useAbTestContext = () => {
    return useContext(AbTestContext);
};

type Props = {
    children: ReactNode | ReactNode[];
};

export const AbTestContextProvider = (props: Props) => {
    const { children } = props;
    const { optimizelyKey } = usePageContext();
    const [forcedVariations, setForcedVariations] = useState({});
    const [readerAvailable, setReaderAvailable] = useState(false);

    // initialize AB test client and create list of all experiments and variations
    useEffect(() => {
        const init = async () => {
            try {
                reader.initialize();
                reader.whenAvailable(() => setReaderAvailable(true), 2000);
                const queryParams = getQueryParams();
                const forcedVariationQueryParams = Object.keys(queryParams)
                    .filter((key) => key.startsWith("AB_"))
                    .reduce((queryParamsAcc, experimentQueryParamKey) => {
                        const queryParamValue = queryParams[experimentQueryParamKey];
                        const experimentKey = experimentQueryParamKey.substring(3);

                        // if there are multiple variations specified for the same key, use the last one
                        if (Array.isArray(queryParamValue)) {
                            return {
                                ...queryParamsAcc,
                                [experimentKey]: queryParamValue[queryParamValue.length - 1]
                            };
                        }
                        return { ...queryParamsAcc, [experimentKey]: queryParamValue };
                    }, {});

                const forcedVariationLocalhost = Object.keys(localStorage)
                    .filter((key) => key.startsWith("AB_"))
                    .reduce((queryParamsAcc, experimentQueryParamKey) => {
                        const queryParamValue = localStorage.getItem(experimentQueryParamKey);
                        const experimentKey = experimentQueryParamKey.substring(3);

                        // if there are multiple variations specified for the same key, use the last one
                        if (Array.isArray(queryParamValue)) {
                            return {
                                ...queryParamsAcc,
                                [experimentKey]: queryParamValue[queryParamValue.length - 1]
                            };
                        }
                        return { ...queryParamsAcc, [experimentKey]: queryParamValue };
                    }, {});

                const forcedVariationsOverloads = Object.assign(
                    {},
                    forcedVariationLocalhost,
                    forcedVariationQueryParams
                );

                // Go through the overloads and make sure they are all in sync between query parm and local storage
                Object.entries(forcedVariationsOverloads).forEach(([key, value]) => {
                    localStorage.setItem(key, value as string);
                });

                setForcedVariations(forcedVariationsOverloads);
            } catch (error) {
                /** AB testing error should not break the application */
                newRelicWrapper.logPageAction("packaging-studio-ab-test-initialization-failed", {
                    errorMesssage: error.message
                });
            }
        };
        init();
    }, []);

    const getForcedVariation = useCallback(
        (experimentKey: string) => forcedVariations[experimentKey],
        [forcedVariations]
    );

    const context = useMemo(() => {
        if (!readerAvailable) {
            return undefined;
        }

        return {
            getVariation: (experimentKey: string) => {
                const forcedVariation = getForcedVariation(experimentKey);
                return forcedVariation || reader.getVariation(experimentKey);
            },
            isExperimentUsingVariation: async (experimentKey: string, variationKey: string) => {
                const forcedVariation = getForcedVariation(experimentKey);
                const clientVariation = forcedVariation || reader.getVariation(experimentKey);
                return clientVariation === variationKey;
            },
            trackImpression: async (experimentKey: string, variationKey: string) => {
                const forcedVariation = getForcedVariation(experimentKey);
                const storeExpKey = windowExists() ? JSON.parse(localStorage.getItem("trackExperiments") || "{}") : {};
                localStorage.setItem(
                    "trackExperiments",
                    JSON.stringify({ ...storeExpKey, [experimentKey]: variationKey })
                );
                if (forcedVariation) {
                    if (isDevelopmentMode()) {
                        // eslint-disable-next-line no-console
                        console.log("will track impression for:", { experimentKey, forcedVariation });
                    }
                    return;
                }
                await impression.fireImpression(experimentKey, reader.getVariation(experimentKey), optimizelyKey);
            },
            isForcedVariation: (experimentKey: string, variationKey: string) => {
                const forcedVariation = getForcedVariation(experimentKey);
                return forcedVariation === variationKey;
            },
            getForcedVariation,

            isExperimentActive: async (experimentKey: string) => {
                const forcedVariation = getForcedVariation(experimentKey);
                if (forcedVariation) {
                    return false;
                }

                try {
                    const variation = reader.getVariation(experimentKey);
                    if (!variation) {
                        return false;
                    }
                } catch {
                    // if unable to get key from optimizely just disable experiment
                    return false;
                }

                return true;
            }
        };
    }, [getForcedVariation, optimizelyKey, readerAvailable]);

    const { Provider } = AbTestContext;

    return <Provider value={context}>{children}</Provider>;
};

AbTestContextProvider.displayName = "AbTestContextProvider";
