import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import newRelicWrapper from "../clients/newRelicWrapper";

export enum ErrorType {
    none,
    page,
    toast
}

interface ErrorData {
    errorCode?: string;
    errorMessage?: string;
    stackTrace?: string;
    originalError?: Error;
}

interface ErrorContextProps {
    errorShown: ErrorType;
    errorData?: ErrorData;
    showError: (errorShown: ErrorType, errorData?: ErrorData) => void;
}

interface Props {
    children: ReactNode;
}

const ErrorContext = createContext<ErrorContextProps>({ errorShown: ErrorType.none, showError: () => {} });

export const useErrorContext = () => {
    return useContext(ErrorContext);
};

export const ErrorProvider = ({ children }: Props) => {
    const [errorShown, setErrorShown] = useState<ErrorType>(ErrorType.none);
    const [errorData, setErrorData] = useState<ErrorData | undefined>(undefined);

    const showError = useCallback((errorShown: ErrorType, errorData?: ErrorData): void => {
        setErrorShown(errorShown);
        setErrorData(errorData);
        const extraInfo = {
            errorCode: errorData?.errorCode,
            errorMessage: errorData?.errorMessage
        };
        if (errorData?.originalError) {
            newRelicWrapper.noticeError(errorData?.originalError, extraInfo);
        }
        if (errorShown === ErrorType.page) {
            newRelicWrapper.logPageAction("studio-errorpage", extraInfo);
        } else if (errorShown === ErrorType.toast) {
            newRelicWrapper.logPageAction("studio-toast", extraInfo);
        }
    }, []);

    const content = useMemo(() => ({ errorShown, errorData, showError }), [errorShown, errorData, showError]);
    return <ErrorContext.Provider value={content}>{children}</ErrorContext.Provider>;
};

ErrorProvider.displayName = "ErrorProvider";
