import { getQueryParams, isCareAgent, windowExists } from "../utilities/utilities";
import { getUserSessionId, getStudioUniqueSessionId } from "../tracking/generalTrackingData";

declare global {
    interface Window {
        newrelic: INewRelic;
        QuantumMetricAPI?: {
            getReplay?: () => string;
        };
        QuantumMetricOnload: () => void;
    }
}

interface INewRelic {
    setCustomAttribute: (attributeName: string, attributeValue: string | boolean | number) => void;
    addPageAction: (eventName: string, includedMetadata?: Record<string, any>) => void;
    noticeError: (error: string | Error, includedMetadata?: Record<string, any>) => void;
}

function cleanseAttributeName(input: string) {
    // allowable characters retrieved from
    // https://docs.newrelic.com/docs/insights/insights-data-sources/custom-data/insights-custom-data-requirements-limits#general
    return input.replace(/[^a-zA-Z0-9_.:]/g, "_");
}

class NewRelicWrapper {
    NewRelic: INewRelic;

    constructor(NewRelic: INewRelic) {
        this.NewRelic = NewRelic;
        if (windowExists()) {
            this.NewRelic.setCustomAttribute("metadata/href", window.location.href);
            const queryParams = getQueryParams();
            Object.keys(queryParams).forEach((param) => {
                if (param === "selectedOptions") {
                    Object.keys(queryParams[param]!).forEach((option) => {
                        this.NewRelic.setCustomAttribute(
                            `param/selectedOptions/${cleanseAttributeName(option)}`,
                            queryParams[param]![option]
                        );
                    });
                } else {
                    this.NewRelic.setCustomAttribute(`param/${cleanseAttributeName(param)}`, queryParams[param]);
                }
            });
            if (window.QuantumMetricAPI && window.QuantumMetricAPI.getReplay) {
                this.NewRelic.setCustomAttribute("QMReplayLink", window.QuantumMetricAPI.getReplay());
            } else {
                window.QuantumMetricOnload = () => {
                    if (window.QuantumMetricAPI && window.QuantumMetricAPI.getReplay) {
                        this.NewRelic.setCustomAttribute("QMReplayLink", window.QuantumMetricAPI.getReplay());
                    }
                };
            }
        }
    }

    setUpUserVars(locale: string) {
        this.NewRelic.setCustomAttribute("studioUserSession", getUserSessionId());
        this.NewRelic.setCustomAttribute("studioUniqueSession", getStudioUniqueSessionId());
        this.NewRelic.setCustomAttribute("metadata/locale", locale);
    }

    setLoadFinished() {
        this.NewRelic.setCustomAttribute("loadingFinished", true);
    }

    updateCustomAttributes(state: {
        productKey?: string;
        productVersion?: number;
        quantity?: number;
        workId?: string;
        mpvId?: string;
    }) {
        try {
            if (state.productKey) {
                this.NewRelic.setCustomAttribute(`param/key`, state.productKey);
            }
            if (state.productVersion) {
                this.NewRelic.setCustomAttribute(`param/productVersion`, state.productVersion);
            }
            if (state.quantity) {
                this.NewRelic.setCustomAttribute(`param/qty`, state.quantity);
            }
            if (state.workId) {
                this.NewRelic.setCustomAttribute(`param/workId`, state.workId);
            }
            if (state.mpvId) {
                this.NewRelic.setCustomAttribute(`param/mpvId`, state.mpvId);
            }
            this.NewRelic.setCustomAttribute(`isCareAgent`, isCareAgent());
            // eslint-disable-next-line no-empty
        } catch {}
    }

    logPageAction(eventName: string, includedMetadata?: Record<string, any>) {
        this.NewRelic.addPageAction(eventName, includedMetadata);
    }

    noticeError(error: string | Error, includedMetadata?: Record<string, any>) {
        this.NewRelic.noticeError(error, includedMetadata);
    }
}

const NewRelic =
    // @ts-ignore
    windowExists() && typeof window.newrelic !== "undefined"
        ? // @ts-ignore
          window.newrelic
        : {
              addPageAction() {},
              noticeError() {},
              setCustomAttribute() {}
          };

const newRelicWrapper = new NewRelicWrapper(NewRelic);
export default newRelicWrapper;
