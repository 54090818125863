import React, { createContext, ReactNode, useContext } from "react";
import { CartTenantDetails } from "@vp/cart-client";

export interface PageContextProps {
    locale: string;
    segmentKey?: string;
    careUrl: string;
    studio3dCareUrl: string;
    studioTranslations?: Record<string, string>;
    UILibraryDomain?: string;
    homeUrl: string;
    myProjectsUrl: string;
    phone: {
        phoneNumber: string;
        shortFormatSchedule: string[];
    };
    miniCartConfig?: CartTenantDetails;
    optimizelyKey?: string;
}

interface Props {
    content: PageContextProps;
    children: ReactNode;
}

const PageContext = createContext<PageContextProps>({
    locale: "",
    careUrl: "",
    studio3dCareUrl: "",
    homeUrl: "",
    myProjectsUrl: "",
    phone: {
        phoneNumber: "",
        shortFormatSchedule: [""]
    }
});

export const usePageContext = () => {
    const result = useContext(PageContext);
    if (!result) {
        throw Error("Missing context.  This must be called within a PageContextProvider");
    }

    return useContext(PageContext);
};

export const PageContextProvider = (props: Props) => {
    const { content, children } = props;
    return <PageContext.Provider value={content}>{children}</PageContext.Provider>;
};

PageContextProvider.displayName = "PageContextProvider";
