import { defineMessages } from "@vp/i18n-helper";

const translatedErrors = defineMessages({
    defaultErrorHeader: {
        id: "packagingStudio.components.errorPage.defaultErrorHeader",
        defaultMessage: "We’re sorry – something went wrong on our end.",
        description: {
            note: "This header shows up when error happened that doesn't match any other error types"
        }
    },
    defaultErrorSubHeader: {
        id: "packagingStudio.components.errorPage.defaultErrorSubHeader",
        defaultMessage:
            "An error has occurred, and we’re working on it! Check back later, or let us help you in the meantime.",
        description: {
            note: "This subheader shows up when error happened that doesn't match any other error types"
        }
    },
    getHelpButtonText: {
        id: "packagingStudio.components.errorPage.getHelpButtonText",
        defaultMessage: "Contact Customer Care",
        description: {
            note: "This button text shows up by default as primary CTA on an error page"
        }
    },
    returnToHomeButtonText: {
        id: "packagingStudio.components.errorPage.returnToHomeButtonText",
        defaultMessage: "Visit our Homepage",
        description: {
            note: "This button text shows up by default as secondary CTA on an error page"
        }
    }
});

export default translatedErrors;
