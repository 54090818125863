import { PanelDesignRequirements } from "@design-stack-ct/interactive-design-engine-core";
import queryString from "query-string";

export function windowExists() {
    return typeof window !== "undefined" && !!window;
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
interface PossibleQueryParams {
    selectedOptions?: Record<string, string>;
    qty?: string;
    key?: string;
    productVersion?: number;
    workId?: string;
    owner?: string;
    documentUrl?: string;
    translationsLocale?: string;
    locale?: string;
    template?: string;
    templateLocale?: string;
    quickStudioAnalytics?: string;
    metadata?: string;
}

export function tryParse(value: string | null | undefined) {
    if (value) {
        try {
            return JSON.parse(value);
            // eslint-disable-next-line no-empty
        } catch {}
    }
    return undefined;
}

export function getQueryParams(search?: string): PossibleQueryParams {
    if ((search && search.length === 0) || (!search && (!windowExists() || window.location.search.length === 0))) {
        return {};
    }
    const rawParams = search
        ? queryString.parse(search.substr(1))
        : queryString.parse(window.location.search.substr(1));

    return Object.assign({}, rawParams, {
        selectedOptions: tryParse(rawParams.selectedOptions as string) || {},
        qty: tryParse(rawParams.qty as string) || ""
    });
}

export function isLocalhost() {
    return windowExists() && /localhost|127\.0\.0\.1|::1/.test(window.location.hostname);
}

export function isStaging() {
    return windowExists() && /staging/.test(window.location.hostname);
}

export function isBranch() {
    return windowExists() && /branches/.test(window.location.hostname);
}

export function isNextSite() {
    return windowExists() && /next/.test(window.location.hostname);
}

export function isProductionSite() {
    return windowExists() && /www.vistaprint/.test(window.location.hostname);
}

export function isDevelopmentMode() {
    return isStaging() || isLocalhost() || isBranch();
}

export function isCareAgent() {
    if (!windowExists()) {
        return false;
    }
    const urlParams = queryString.parse(window.location.search.substr(1));

    // If query params say false remove local storage value to have same state
    if (urlParams.careAgent === "false") {
        window.localStorage.removeItem("vp-care-agent");
    }

    return urlParams.careAgent === "true" || window.localStorage.getItem("vp-care-agent") === "true";
}

export function removeNullQueryStringParams(params: Record<string, string | number | null | undefined>) {
    const newParams = params;
    Object.keys(newParams).forEach((key) =>
        newParams[key] === undefined || newParams[key] === null ? delete newParams[key] : {}
    );
    return newParams;
}

export function buildHistoryUrl(queryParams: Record<string, string | boolean>) {
    const urlParams = getQueryParams();

    const updatedQueryParams = queryParams || {};
    if (isCareAgent()) {
        updatedQueryParams.careAgent = true;
    }
    if (urlParams.owner) {
        updatedQueryParams.owner = urlParams.owner;
    }

    return `${window.location.pathname}?${queryString.stringify(updatedQueryParams)}`;
}

// Handles 100vh on mobile: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
export function setViewportHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
}

export enum PrintColorProductOptions {
    insideOnly = "0/4 - 0/CMYK",
    outsideOnly = "4/0 - CMYK"
}

// TODO: This is temporarily hard coding these product options for print color to the index of the surface specs
// it was verified with the product config team that the order of the panels for DEX products will
// be consistent. Ideally this configuration wouldn't be handled in studio, but from the product side of things
// will have to work with the product config team on this though.
export function resolvePanelIds(panels: PanelDesignRequirements[], productOptions: Record<string, string>) {
    if (productOptions.PrintColor === PrintColorProductOptions.insideOnly) {
        return [panels[1].id];
    }
    if (productOptions.PrintColor === PrintColorProductOptions.outsideOnly) {
        return [panels[0].id];
    }

    return panels.map((panel) => panel.id);
}
