import { UdsResponse } from "@design-stack-ct/documents-sdk";
import { getQueryParams, isCareAgent } from "../utilities/utilities";
import config from "../utilities/config";
import { CalciferResponse } from "./calciferClient";
import { cloneDocument } from "./saveClient";

const WES_HOST = "https://workentityservice.design.vpsvc.com/api";
const entityCode = 20;

export async function getWorkEntity(workId: string, accessToken: string) {
    const url = `${WES_HOST}/v1/works/${encodeURIComponent(workId)}?includeHidden=true`;
    const response = await fetch(url, {
        headers: {
            From: config.studio3dRequestor,
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    });
    const json = await response.json();

    if (response.ok) {
        return json;
    }
    // eslint-disable-next-line no-throw-literal
    throw { ...json, errorChain: `${entityCode}-${json.StatusCode}` };
}

interface ConstructWorkEntityProps {
    udsResponse: UdsResponse;
    calciferConfig: CalciferResponse;
    existingWork: Record<string, any>;
    locale: string;
    newWorkName?: string;
}

export async function constructWorkEntity({
    udsResponse,
    calciferConfig,
    existingWork,
    locale,
    newWorkName
}: ConstructWorkEntityProps) {
    const workEntity = existingWork;

    if (!workEntity.merchandising) {
        workEntity.merchandising = {};
    }

    workEntity.workName = newWorkName || workEntity.workName || calciferConfig.productName;
    workEntity.merchandising.merchandisingSelections = calciferConfig.customerSelectedOptions;
    workEntity.merchandising.quantity = calciferConfig.quantity;

    if (calciferConfig.mpvId) {
        workEntity.merchandising.mpvUrl = `${config.mpv_url}/mpv/vistaprint/${locale}/${calciferConfig.mpvId}`;
    }

    // the new way of saving product data
    workEntity.product = workEntity.product || {};
    workEntity.product.key = calciferConfig.productKey;
    workEntity.product.version = calciferConfig.productVersion;

    workEntity.resources = workEntity.resources || {};

    workEntity.design = workEntity.design || {};
    workEntity.design.metadata = workEntity.design.metadata || {};
    workEntity.design.metadata.udsDocumentUrl = udsResponse._links.self.href;

    // we only need to keep the studio selected product options if we actually have some,
    // and if the customer still has not selected a complete set of product options
    if (
        calciferConfig.selectedOptions &&
        JSON.stringify(Object.keys(calciferConfig.selectedOptions).sort()) !==
            JSON.stringify(Object.keys(calciferConfig.customerSelectedOptions).sort())
    ) {
        // WES throws a validation error when trying to put json here and not stringifying it :(
        workEntity.design.metadata.studioSelectedProductOptions = JSON.stringify(calciferConfig.selectedOptions);
    } else if (workEntity.design.metadata.studioSelectedProductOptions) {
        // if we no longer need to keep the studio selected product options around because the customer
        // has a full set of product options that they have selected, just remove these from the work
        delete workEntity.design.metadata.studioSelectedProductOptions;
    }

    workEntity.design.designUrl = udsResponse._links.documentRevision.href;
    workEntity.design.manufactureUrl = `https://instructions.documents.cimpress.io/v3/instructions:drawing?documentUri=${encodeURIComponent(
        udsResponse._links.documentRevision.href
    )}`;
    workEntity.design.displayUrl = `https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=${encodeURIComponent(
        udsResponse._links.documentRevision.href
    )}`;

    workEntity.design.editUrl = `${window.location.pathname}?workId=\${workId}`;

    return workEntity;
}

export async function postWorkEntity(workEntity: Record<string, any>, accessToken: string) {
    let url = `${WES_HOST}/v1/works/${workEntity.workId ? `${encodeURIComponent(workEntity.workId)}/update` : ""}`;

    if (isCareAgent()) {
        const { owner } = getQueryParams();

        if (!owner) {
            throw new Error("Please define owner query param");
        }
        url += `?ownerId=${owner}`;
    }

    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(workEntity),
        headers: {
            From: config.studio3dRequestor,
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    });

    return response.json();
}

export async function patchWorkName(workId: string, authToken: string, newName: string) {
    const url = `${WES_HOST}/v2/works/${workId}/name`;
    const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify({ workName: newName }),
        headers: {
            From: config.studio3dRequestor,
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`
        }
    });

    return response;
}

export async function cloneWorkEntity(workId: string, authToken: string, newName: string) {
    const workEntityToClone = await getWorkEntity(workId, authToken);
    delete workEntityToClone.workId;
    delete workEntityToClone.workVersionId;

    const documentInfo = await cloneDocument(workEntityToClone.design.designUrl, authToken);
    workEntityToClone.workName = newName;
    workEntityToClone.design.editUrl = `${window.location.pathname}?workId=\${workId}`;
    workEntityToClone.design.designUrl = documentInfo.documentRevisionUrl;
    workEntityToClone.design.displayUrl = documentInfo.previewInstructionSourceUrl;
    workEntityToClone.design.manufactureUrl = documentInfo.instructionSourceUrl;
    return postWorkEntity(workEntityToClone, authToken);
}
