import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import newRelicWrapper from "../clients/newRelicWrapper";
import { getQueryParams } from "../utilities/utilities";
import { CalciferResponse, fetchStudioConfig } from "../clients/calciferClient";
import { useInitialProductData } from "../hooks/useInitialProductData";
import { useErrorContext, ErrorType } from "./ErrorProvider";
import { usePageContext } from "./PageContextProvider";
import config from "../utilities/config";
import errorCodes from "../utilities/errorCodes";

interface Props {
    children: ReactNode;
}

const CalciferContext = createContext<CalciferResponse | undefined>(undefined);

export const useCalcifer = () => {
    return useContext(CalciferContext);
};

export const CalciferProvider = (props: Props) => {
    const { children } = props;
    const { productKey, productVersion, selectedOptions, quantity, template } = useInitialProductData();
    const { locale } = usePageContext();
    const { showError } = useErrorContext();
    const [calciferConfig, setCalciferConfig] = useState<CalciferResponse | undefined>();

    useEffect(() => {
        if (productKey && !calciferConfig) {
            (async () => {
                try {
                    const { templateLocale } = getQueryParams();
                    const config = await fetchStudioConfig({
                        productKey,
                        productVersion,
                        selectedOptions,
                        locale,
                        quantity,
                        template,
                        templateLocale
                    });
                    newRelicWrapper.updateCustomAttributes(config);
                    setCalciferConfig(config);
                } catch (error) {
                    setCalciferConfig({ productKey });
                    showError(ErrorType.page, {
                        errorCode: `${config.entityCode}-${errorCodes.START_STUDIO}-${error.errorChain}`,
                        errorMessage: error.title
                    });
                }
            })();
        }
    }, [productKey, productVersion, selectedOptions, calciferConfig, locale, showError, quantity, template]);

    return <CalciferContext.Provider value={calciferConfig}>{children}</CalciferContext.Provider>;
};

CalciferProvider.displayName = "CalciferProvider";
