import React, { ReactNode } from "react";
import newRelicWrapper from "../../clients/newRelicWrapper";
import errorCodes from "../../utilities/errorCodes";
import { ErrorPage } from "./ErrorPage";
import config from "../../utilities/config";

const errorCode = `${config.entityCode}-${errorCodes.ERROR_BOUNDARY}`;

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    errorMessage?: string;
}

class StudioErrorBoundary extends React.Component<Props, State> {
    static displayName: string;

    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    // disable reloading while implementing gatsby
    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message || error };
    }

    // if I make this functions static like eslint wants, then it doesn't run
    // eslint-disable-next-line class-methods-use-this
    componentDidCatch(error: Error, errorInfo: any) {
        // errorInfo includes the componentStack
        newRelicWrapper.noticeError(error, errorInfo);
        const errorMessage = error.message || error;
        newRelicWrapper.logPageAction("studio-errorpage", {
            errorCode,
            errorMessage
        });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage errorCodeOverride={errorCode} errorMessageOverride={this.state.errorMessage} />;
        }

        return this.props.children;
    }
}

StudioErrorBoundary.displayName = "StudioErrorBoundary";

export default StudioErrorBoundary;
