import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { SwanHead, SWAN_STYLE_KEY_MAP, getRootClassNames, useBrowserClasses } from "@vp/swan";

const styleSheetKeys = [
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.controlIcon,
    SWAN_STYLE_KEY_MAP.selectionSet,
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.grid, // this is used by the consent manager
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.tabs,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.progressiveImage,
    SWAN_STYLE_KEY_MAP.utility
];

interface Props {
    locale: string;
    UILibraryDomain: string;
    children: ReactNode;
}

export default function HeadCollection({ children, locale, UILibraryDomain }: Props) {
    const bodyClassName = getRootClassNames();
    const htmlClassName = useBrowserClasses();
    const language = locale?.split("-")[0];

    return (
        <>
            <SwanHead styleSheetKeys={styleSheetKeys} renderWith={Helmet} renderStyleContentAsChildren />
            <Helmet htmlAttributes={{ class: htmlClassName }}>
                <html lang={language || "en"} />
                <title>Vistaprint | Studio 3D</title>

                <meta name="viewport" content="width=device-width" />
                <meta name="format-detection" content="telephone=no" />
                <meta name="theme-color" content="#006196" />
                <link rel="preconnect" href="https://rendering.documents.cimpress.io" />
                <link rel="shortcut icon" href={`${UILibraryDomain}/ui-library/images/favicon/favicon.ico`} />
                <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href={`${UILibraryDomain}/ui-library/images/favicon/vistaprint-favorites-76-76-2014-2x.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="120x120"
                    href={`${UILibraryDomain}/ui-library/images/favicon/vistaprint-favorites-60-60-2014-2x.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="76x76"
                    href={`${UILibraryDomain}/ui-library/images/favicon/vistaprint-favorites-76-76-2014.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="60x60"
                    href={`${UILibraryDomain}/ui-library/images/favicon/vistaprint-favorites-60-60-2014.png`}
                />
            </Helmet>
            <div className={bodyClassName}>{children}</div>
        </>
    );
}

HeadCollection.displayName = "HeadCollection";
