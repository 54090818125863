import { v4 } from "uuid";
import { DSS } from "@vp/types-ddif";
import { isCareAgent, windowExists } from "../utilities/utilities";

export function getUserSessionId() {
    // Assuming tracking will be enable 100% on all studio pages
    return (
        // @ts-ignore
        (windowExists() && window.tracking && window.tracking.visit && window.tracking.visit()) || "tracking-not-setup"
    );
}

function getQMReplayLink() {
    return (
        windowExists() &&
        // @ts-ignore
        window.QuantumMetricAPI &&
        // @ts-ignore
        window.QuantumMetricAPI.getReplay &&
        // @ts-ignore
        window.QuantumMetricAPI.getReplay()
    );
}

const studioUniqueSession = v4();
export function getStudioUniqueSessionId() {
    return studioUniqueSession;
}

let trackingDocumentMetadata = {};
export function generateDocumentMetadata(document: DSS.DesignDocument) {
    const firstPanelId = document.document.panels[0].id;
    const documentSource =
        document.metadata?.documentSources?.panels?.find((p) => p.id === firstPanelId)?.source ??
        document.metadata?.documentSources?.panels?.[0]?.source;
    if (documentSource) {
        trackingDocumentMetadata = {
            documentSource
        };
    }
}

export function getGeneralTrackingData() {
    return {
        studioUniqueSession: getStudioUniqueSessionId(),
        studioUserSession: getUserSessionId(),
        qmReplayLink: getQMReplayLink()
    };
}

export function getStudioTrackingMetadata() {
    return {
        careAgent: isCareAgent(),
        ...trackingDocumentMetadata
    };
}
