module.exports = [{
      plugin: require('../node_modules/@wardpeet/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@vp/gatsby-theme-performance/gatsby-browser.js'),
      options: {"plugins":[],"applyCriticalCss":true,"clientDecoratorConfigParams":{"apply":false},"ssrDecoratorConfigParams":{"apply":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
