/* eslint-disable import/prefer-default-export */
import React from "react";
import StudioLayout from "./src/StudioLayout";

export const wrapPageElement = ({ element, props }) => {
    return <StudioLayout {...props}>{element}</StudioLayout>;
};
wrapPageElement.displayName = "wrapPageElement";

export const onClientEntry = () => {
    if (typeof window.IntersectionObserver === "undefined") {
        // this is used by swan
        // eslint-disable-next-line no-unused-expressions
        import("intersection-observer");
    }
};
