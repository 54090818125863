type ExperimentConfType = {
    [key: string]: {
        experimentId: string;
        experimentName: string;
        variationId: string;
        variationName: string;
    };
};
export const experimentConfigObj: ExperimentConfType = {
    "PRD-CTNYEUWW": {
        experimentId: "24072880188",
        experimentName: "NA_Brochures_Studio3D",
        variationId: "24118040259",
        variationName: "var_1"
    },
    "PRD-1EBG8KVL": {
        experimentId: "24072880188",
        experimentName: "NA_Brochures_Studio3D",
        variationId: "24118040259",
        variationName: "var_1"
    }
};

export const exceptionEventOnExperiment = ["Product Viewed", "Studio Continue"];
