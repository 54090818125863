import { useEffect, useState } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { getWorkEntity } from "../clients/workEntityClient";
import { getQueryParams } from "../utilities/utilities";
import { useErrorContext, ErrorType } from "../providers/ErrorProvider";
import config from "../utilities/config";
import errorCodes from "../utilities/errorCodes";

interface InitialProductData {
    productKey?: string;
    productVersion?: number;
    selectedOptions?: Record<string, string>;
    documentUrl?: string;
    quantity?: string;
    template?: string;
}

export function useInitialProductData(): InitialProductData {
    const [initialProductData, setInitialProductData] = useState<InitialProductData>({});
    const { auth, identity } = useIdentityContext();
    const accessToken = auth?.getToken();
    const { isSignedIn } = identity;
    const { showError } = useErrorContext();

    useEffect(() => {
        if (initialProductData.productKey) {
            return;
        }
        (async () => {
            const { key, productVersion, selectedOptions, workId, documentUrl, qty, template } = getQueryParams();

            if (key) {
                setInitialProductData({
                    productKey: key,
                    productVersion,
                    selectedOptions,
                    documentUrl,
                    quantity: qty,
                    template
                });
            } else if (workId && accessToken) {
                try {
                    const work = await getWorkEntity(workId, accessToken);
                    setInitialProductData({
                        productKey: work.product.key,
                        productVersion: work.product.version,
                        selectedOptions: work.merchandising.merchandisingSelections,
                        documentUrl: work.design.designUrl,
                        quantity: work.merchandising?.quantity
                    });
                } catch (error) {
                    if (!isSignedIn && error.errorChain.endsWith("20-403")) {
                        auth.signIn();
                    } else {
                        showError(ErrorType.page, {
                            errorCode: `${config.entityCode}-${errorCodes.START_STUDIO}-${error.errorChain}`,
                            errorMessage: error.message
                        });
                    }
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken, showError, isSignedIn]);

    return initialProductData;
}
