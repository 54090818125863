import queryString from "query-string";
import { CalculatedSurface } from "@design-stack-ct/product-surface-specs-client";
import { CimDoc } from "@design-stack-ct/cdif-types";
import config from "../utilities/config";
import { getCountry } from "../utilities/locale";
import { removeNullQueryStringParams } from "../utilities/utilities";

const entityCode = 40;

interface initialConfigProps {
    productKey: string;
    productVersion?: number;
    selectedOptions?: Record<string, string>;
    locale: string;
    quantity?: string;
    template?: string;
    templateLocale?: string;
}

export interface CalciferResponse {
    mpvId?: string;
    productKey: string;
    productVersion?: number;
    productName?: string;
    quantity?: number;
    selectedOptions?: Record<string, string>;
    customerSelectedOptions?: Record<string, string>;
    designViews?: {
        designViews: CalculatedSurface[];
    };
    designDocument?: CimDoc;
}

export async function fetchStudioConfig({
    productKey,
    productVersion,
    selectedOptions,
    locale,
    quantity,
    template,
    templateLocale
}: initialConfigProps) {
    const requestParams = {
        productKey,
        // mpvId,
        productVersion,
        selectedOptions: JSON.stringify(selectedOptions),
        qty: quantity ? quantity.toString() : null,
        market: getCountry(locale),
        locale,
        requestor: config.studio3dRequestor,
        template,
        templateLocale
    };

    const filteredParams = removeNullQueryStringParams(requestParams);

    const url = `https://cdn.studio-calcifer.design.vpsvc.com/v2/initializedStudioConfig/?${queryString.stringify(
        filteredParams
    )}`;
    const response = await fetch(url);
    const json = await response.json();

    if (response.ok) {
        return json;
    }
    // eslint-disable-next-line no-throw-literal
    throw { ...json, errorChain: `${entityCode}-${json.status}-${json.errorChain}` };
}
