import React, { useContext, createContext, useEffect, useState, useMemo, ReactNode } from "react";
import { useCalcifer } from "../../providers/CalciferProvider";
import { getQueryParams } from "../utilities";
import { useAbTestContext } from "./ABTestContext";

type ContextData = {
    isTestActive: boolean;
};

const context = createContext<ContextData | undefined>(undefined);

export function usePresentationFolders() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context.  This must be called within a PresentationFoldersABProvider");
    }
    return result;
}

/**
 * For this AB test, studio5 is always variation_1
 * studio3d is always variation_2
 */
enum Variations {
    Control = "variation_1",
    Enabled = "variation_2"
}

export const PresentationFoldersExperimentData: ExperimentData = {
    experimentKey: "presentation_folders_-_3d_studio_test",
    experimentName: "Presentation Folders - 3D Studio Test",
    variations: Variations
};

type Props = {
    children: ReactNode | ReactNode[];
};

export const PresentationFoldersABProvider = ({ children }: Props) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const { experimentKey } = PresentationFoldersExperimentData;
    const [isTestActive, setIsTestActive] = useState(false);
    const calciferConfig = useCalcifer();
    const { workId } = getQueryParams();

    const productKey = calciferConfig?.productKey;
    // only for new design Presentation Folders
    const conductExperiment = productKey === "PRD-ZMIEC1QY" && !workId;

    useEffect(() => {
        if (!experimentKey || !ABTest) {
            return;
        }

        const { isExperimentUsingVariation, trackImpression, isExperimentActive } = ABTest;
        isExperimentActive(experimentKey).then((active: boolean) => setIsTestActive(active));
        const trackImpressionIfInVariant = async (variation: Variations) => {
            const res = await isExperimentUsingVariation(experimentKey, variation);
            if (res && conductExperiment) {
                trackImpression(experimentKey, variation);
            }
        };

        trackImpressionIfInVariant(Variations.Enabled);
        trackImpressionIfInVariant(Variations.Control);
    }, [experimentKey, ABTest, calciferConfig, conductExperiment]);

    const contextObject = useMemo(
        () => ({
            isTestActive
        }),
        [isTestActive]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

PresentationFoldersABProvider.displayName = "PresentationFoldersABProvider";
