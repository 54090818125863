import "core-js/stable";
import "regenerator-runtime/runtime";

import React, { ReactNode } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { i18n } from "@vp/i18n-helper";

import {
    SiteContent,
    MainContentStart,
    SiteMain,
    SwanProvider,
    SWAN_STYLE_KEY_MAP,
    ScreenClassProvider
} from "@vp/swan";

import { IdentityProvider } from "@design-stack-vista/identity-provider";
import HeadCollection from "./components/HeadCollection";
import { PageContextProvider, PageContextProps } from "./providers/PageContextProvider";
import { CalciferProvider } from "./providers/CalciferProvider";
import { ErrorProvider } from "./providers/ErrorProvider";
import initI18n from "./utilities/i18n";
import { isCareAgent, isProductionSite, windowExists } from "./utilities/utilities";
import StudioErrorBoundary from "./components/Error/StudioErrorBoundary";
import { TrackingProvider } from "./providers/TrackingProvider";
import { DialogContentProvider } from "./providers/DialogContentProvider";
import { PresentationFoldersABProvider } from "./utilities/ABTests/PresentationFoldersABProvider";
import { AbTestContextProvider } from "./utilities/ABTests/ABTestContext";
import { Studio6ABTestProvider } from "./utilities/ABTests/Studio6ABTestProvider";
import Studio6ABTestWrapper from "./utilities/ABTests/Studio6ABTestWrapper";
import { PatternABTestProvider } from "./utilities/ABTests/PatternABTestProvider";

export default function StudioLayout({
    children,
    pageContext
}: {
    children: ReactNode;
    pageContext: PageContextProps;
}) {
    const uiLibraryDomain = pageContext.UILibraryDomain || "https://www.vistaprint.com";
    // non-studio pages like auth-callback still need a locale defined.  en-us will be the default.
    const locale = pageContext.locale || "en-us";

    //  only run this once - preferably server side
    //  this may be where the i18nextProvider comes into play - the global instance of i18n is not initialized clientside,
    // but perhaps the provider will provide the initialized instance
    // can't use just isInitialized because then this will only be run once serverside (each static page render shares the same global i18n)
    if (pageContext.studioTranslations) {
        // @ts-ignore
        if (!i18n.isInitialized || i18n.language !== locale.toLowerCase()) {
            initI18n(locale.toLowerCase(), pageContext.studioTranslations);
        }
    }

    return (
        <SwanProvider
            swanTenant="vistaprint"
            swanLocale={locale}
            styleConfig={{
                domain: uiLibraryDomain,
                defaultKeys: [
                    SWAN_STYLE_KEY_MAP.core,
                    SWAN_STYLE_KEY_MAP.button,
                    SWAN_STYLE_KEY_MAP.controlIcon,
                    SWAN_STYLE_KEY_MAP.selectionSet,
                    SWAN_STYLE_KEY_MAP.alertBox,
                    SWAN_STYLE_KEY_MAP.popover,
                    SWAN_STYLE_KEY_MAP.modalDialog,
                    SWAN_STYLE_KEY_MAP.grid, // this is used by the consent manager
                    SWAN_STYLE_KEY_MAP.accordion,
                    SWAN_STYLE_KEY_MAP.tabs,
                    SWAN_STYLE_KEY_MAP.listbox,
                    SWAN_STYLE_KEY_MAP.toggleSwitch,
                    SWAN_STYLE_KEY_MAP.hidden,
                    SWAN_STYLE_KEY_MAP.icon,
                    SWAN_STYLE_KEY_MAP.visible,
                    SWAN_STYLE_KEY_MAP.standardTile,
                    SWAN_STYLE_KEY_MAP.progressiveImage,
                    SWAN_STYLE_KEY_MAP.utility
                ]
            }}
        >
            <HeadCollection locale={locale} UILibraryDomain={uiLibraryDomain}>
                <IdentityProvider
                    locale={locale}
                    isProd={isProductionSite()}
                    isCareAgent={isCareAgent()}
                    ownerId={""}
                    redirectUriOrigin={
                        !isProductionSite() && windowExists()
                            ? `${window.location.origin}/packaging-studio/auth-callback/`
                            : undefined
                    }
                >
                    <PageContextProvider content={pageContext}>
                        <AbTestContextProvider>
                            <ErrorProvider>
                                <CalciferProvider>
                                    <TrackingProvider>
                                        <DialogContentProvider>
                                            <ScreenClassProvider>
                                                <PresentationFoldersABProvider>
                                                    <Studio6ABTestProvider>
                                                        <PatternABTestProvider>
                                                            <SiteContent>
                                                                <SiteMain role="main">
                                                                    <MainContentStart />
                                                                    <StudioErrorBoundary>
                                                                        <SkeletonTheme
                                                                            color="#d8d8d8"
                                                                            highlightColor="#bdbdbd"
                                                                        >
                                                                            <Studio6ABTestWrapper>
                                                                                {children}
                                                                            </Studio6ABTestWrapper>
                                                                        </SkeletonTheme>
                                                                    </StudioErrorBoundary>
                                                                </SiteMain>
                                                            </SiteContent>
                                                        </PatternABTestProvider>
                                                    </Studio6ABTestProvider>
                                                </PresentationFoldersABProvider>
                                            </ScreenClassProvider>
                                        </DialogContentProvider>
                                    </TrackingProvider>
                                </CalciferProvider>
                            </ErrorProvider>
                        </AbTestContextProvider>
                    </PageContextProvider>
                </IdentityProvider>
            </HeadCollection>
        </SwanProvider>
    );
}

StudioLayout.displayName = "StudioLayout";
