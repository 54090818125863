import React, { FC, useContext, useState, createContext, useMemo, useCallback } from "react";

export enum DialogContentType {
    None = "none",
    File = "file",
    Help = "help"
}

type Data = {
    currentContentType: DialogContentType;
    setCurrentContentType: (
        contentType: DialogContentType | ((current: DialogContentType) => DialogContentType),
        highlight?: boolean
    ) => void;
};

const context = createContext<Data | undefined>(undefined);

export function useDialogContent() {
    const result = useContext(context);
    if (!result) {
        throw Error("No Content found. Must be called within a DialogContentProvider.");
    }
    return result;
}

export const DialogContentProvider: FC = ({ children }) => {
    const [currentContentType, setCurrentContentType] = useState<DialogContentType>(DialogContentType.None);
    const { Provider } = context;

    const setContentType = useCallback(
        (contentType: DialogContentType | ((current: DialogContentType) => DialogContentType)) => {
            setCurrentContentType(contentType);
        },
        [setCurrentContentType]
    );

    const value = useMemo(() => {
        return {
            currentContentType,
            setCurrentContentType: setContentType
        };
    }, [currentContentType, setContentType]);

    return <Provider value={value}>{children}</Provider>;
};
DialogContentProvider.displayName = "DialogContentProvider";
