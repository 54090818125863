import { createDocument, createRevision, getDocument } from "@design-stack-ct/documents-sdk";
import { convertToAuthHeaders } from "@design-stack-ct/utility-core/dist/auth";
import { CimDoc } from "@design-stack-ct/cdif-types";
import { buildHistoryUrl, getQueryParams } from "../utilities/utilities";
import { CalciferResponse } from "./calciferClient";
import { constructWorkEntity, getWorkEntity, postWorkEntity } from "./workEntityClient";

export async function saveDesign(
    _cimDoc: CimDoc,
    calciferConfig: CalciferResponse,
    locale: string,
    accessToken: string,
    newWorkName?: string
) {
    const { workId } = getQueryParams();
    const existingWork = workId ? await getWorkEntity(workId, accessToken) : {};
    const authHeaders = convertToAuthHeaders({ authType: "bearer", value: accessToken });

    // Must remove tenant from cimdoc so we can use our own
    const cimDoc = {
        ..._cimDoc,
        tenant: undefined
    };

    const existingDocumentURL = existingWork?.design?.metadata?.udsDocumentUrl;
    const udsResponse = existingDocumentURL
        ? await createRevision({
              cimDoc,
              headers: authHeaders,
              documentUrl: existingDocumentURL,
              etag: "*"
          })
        : await createDocument({ cimDoc, headers: authHeaders });
    if (!udsResponse) {
        throw new Error("No response from UDS");
    }

    const newWork = await constructWorkEntity({
        existingWork,
        locale,
        calciferConfig,
        udsResponse,
        newWorkName
    });
    const savedWork = await postWorkEntity(newWork, accessToken);
    // update url
    window.history.pushState("update-workId", "Title", buildHistoryUrl({ workId: savedWork.workId }));
    return savedWork;
}

export async function cloneDocument(documentRevisionUrl: string, authToken: string) {
    const authHeaders = convertToAuthHeaders({ authType: "bearer", value: authToken });
    const { cimDoc } = await getDocument(documentRevisionUrl, { headers: authHeaders });
    const udsResponse = await createDocument({ cimDoc, headers: authHeaders });

    if (!udsResponse) {
        throw new Error("No response from UDS");
    }

    return {
        url: udsResponse._links.self.href,
        documentRevisionUrl: udsResponse._links.documentRevision.href,
        instructionSourceUrl: udsResponse._links.drawingInstructions.href,
        previewInstructionSourceUrl: udsResponse._links.previewInstructions.href
    };
}
